import { getCurrentMemberAccount, getMyAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { Experiment } from '../../../constants';

export async function getLoyaltyAccount(flowAPI: PlatformControllerFlowAPI): Promise<LoyaltyAccount> {
  const { httpClient, experiments } = flowAPI;
  const enableReturningUsersFlow = experiments.enabled(Experiment.CheckoutWidgetReturningUsers);

  try {
    const response = await httpClient.request(
      enableReturningUsersFlow ? getMyAccount({}) : getCurrentMemberAccount({}),
    );
    return response.data.account!;
  } catch (error) {
    // When user has no points loyalty account doesn't exist
    if (error instanceof Error && error.message.includes('404')) {
      return {};
    }

    throw error;
  }
}

import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';

import { calculateMaxOrderDiscount } from './calculateMaxOrderDiscount';
import { FlexibleRewardConfig } from './getFlexibleRewardConfig';

interface Params {
  checkout?: Checkout;
  flexibleRewardConfig: FlexibleRewardConfig;
  maxFlexibleRewardPointsAmount: number;
}

export function calculateMaxFlexibleRewardDiscount({
  checkout,
  flexibleRewardConfig,
  maxFlexibleRewardPointsAmount,
}: Params): number {
  const { discountPerPoint } = flexibleRewardConfig;
  const maxOrderDiscount = calculateMaxOrderDiscount(checkout);

  return Math.min(maxOrderDiscount, maxFlexibleRewardPointsAmount * discountPerPoint);
}
